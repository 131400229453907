import axios from "axios";

import { isAuthorized } from "./auth";
import { logErrorToSentry, sentryInstance } from "shared/helpers/utils";

if (import.meta.env.VITE_APP_API_HOST !== "") {
  axios.defaults.withCredentials = true;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (sentryInstance) {
      logErrorToSentry(error);
    }
    isAuthorized(error?.response);
    return Promise.reject(error);
  }
);

export const { post, get } = axios;
